import './Button.scss';

export default function Button({ onClick, label, Icon }) {
  return (
    <button className="linkButton btn cursor-pointer" onClick={onClick}>
      {label}
      {Icon && <Icon className="btn-icon icon" />}
    </button>
  );
}
